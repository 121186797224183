<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <material-card
          color="#4e4e4e"
          title="Vendas"
          text="Vendas e Pedidos realizados (inStore e inApp)"
        >
          <v-toolbar color="#FFF">
            <v-text-field hide-details prepend-icon single-line v-model="pesquisa" title="Código da Venda"></v-text-field>

            <v-spacer></v-spacer>

            <v-toolbar-items>
              <v-btn text color="#4e4e4e" @click="buscarPorCodigo()">Buscar</v-btn>
            </v-toolbar-items>

            <v-item-group></v-item-group>
          </v-toolbar>
          <div v-if="pesquisa === ''">
            <v-data-table :headers="headers" :items="items">
              <template slot="headerCell" slot-scope="{ header }">
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template slot="items" slot-scope="{ item }">
                <td>{{ item.codigoVenda }}</td>
                <td>{{ item.quantidade }}</td>
                <td
                  v-if="item.valorTotal != undefined && item.valorTotal != null"
                >{{ parseFloat(item.valorTotal).toFixed(2).toString().replace('.',',') }}</td>
                <td>{{ parseFloat(item.precoPago).toFixed(2).toString().replace('.',',') }}</td>
                <td>{{ item.formaPagamento }}</td>
                <td>{{ item.tipoPagamento }}</td>
                <td>{{ item.status }}</td>

                <v-btn color="#000" @click="showProduto(item)">Ver</v-btn>
              </template>
            </v-data-table>
          </div>
          <div v-else>
            <v-data-table :headers="headers" :items="items_busca">
              <template slot="headerCell" slot-scope="{ header }">
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template slot="items" slot-scope="{ item }">
                <td>{{ item.codigoVenda }}</td>
                <td>{{ item.quantidade }}</td>
                <td>{{ parseFloat(item.valorTotal).toFixed(2).toString().replace('.',',') }}</td>
                <td>{{ parseFloat(item.precoPago).toFixed(2).toString().replace('.',',') }}</td>
                <td>{{ item.formaPagamento }}</td>
                <td>{{ item.tipoPagamento }}</td>
                <td>{{ item.status }}</td>

                <v-btn color="#000" @click="showProduto(item)">Ver</v-btn>
              </template>
            </v-data-table>
          </div>
        </material-card>
      </v-flex>

      <v-snackbar
        color="#000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbar"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>Produto atualizado com sucesso!</div>
        <v-icon size="16" @click="snackbar = false">mdi-close-circle</v-icon>
      </v-snackbar>

      <v-snackbar
        color="#BA0000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbarerror"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>
          Verifique os dados e tente novamente
          <br />Verifique a conexão com a internet!
        </div>
        <v-icon size="16" @click="snackbarerror = false">mdi-close-circle</v-icon>
      </v-snackbar>
    </v-layout>

    <v-dialog v-model="dialog" width="900">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Informações sobre a Venda</v-card-title>
        <v-card-text v-if="selecionado">Código de Venda: {{selecionado.codigoVenda}}</v-card-text>
        <v-card-text
          v-if="selecionado"
        >Valor Pago: {{(selecionado.precoPago).toFixed(2).replace('.', ',')}}</v-card-text>
        <v-card-text
          v-if="selecionado"
        >Valor Total: {{parseFloat(selecionado.valorTotal).toFixed(2).replace('.', ',')}}</v-card-text>
        <v-card-text v-if="selecionado">Data: {{selecionado.dia}}</v-card-text>
        <div v-if="selecionado && selecionado.endereco">
        <v-card-text v-if="selecionado">Endereço: 
                                    <br/>{{selecionado.endereco.rua}}, {{selecionado.endereco.numero}}
                                    <br/>{{selecionado.endereco.complemento}}
                                    <br/>{{selecionado.endereco.bairro}}
                                    <br/>{{selecionado.endereco.cidade}} - {{selecionado.endereco.estado}} </v-card-text>
        </div>
        <v-card-text v-if="selecionado">Quantidade: {{selecionado.quantidade}}</v-card-text>
        <v-card-text v-if="selecionado && selecionado.cpf">CPF: {{selecionado.cpf}}</v-card-text>
        <v-card-text v-if="selecionado">Forma de Pagamento: {{selecionado.formaPagamento}}</v-card-text>
        <v-card-text v-if="selecionado && selecionado.detalhesPagamento">Bandeira: {{selecionado.detalhesPagamento.bandeira}} - {{selecionado.detalhesPagamento.parcelas}}x</v-card-text>
        <v-card-text v-if="selecionado">Tipo de Pagamento: {{selecionado.tipoPagamento}}</v-card-text>
        <v-card-text v-if="selecionado">Status: {{selecionado.status}}</v-card-text>

        <v-col class="d-flex ml-4 mr-5" cols="4" sm="2">
          <v-select :items="items_status" @input="atualizarStatus" filled label="Atualizar Status"></v-select>
        </v-col>

        <v-card-text v-if="selecionado">Plataforma: {{selecionado.local}}</v-card-text>
        <v-card-text
          v-if="seeAdress()"
        >Endereço: {{selecionado.endereco.rua}}, {{selecionado.endereco.numero}}</v-card-text>
        <v-card-text v-if="selecionado">Frete: {{selecionado.frete}}</v-card-text>
        <v-card-text v-if="selecionado">Comprador: {{selecionado.comprador_nome}}</v-card-text>
        <v-card-text v-if="seeWpp()">Whatsapp: {{selecionado.wpp}}</v-card-text>
        <v-card-text v-if="selecionado">
          <b>Produtos:</b>
        </v-card-text>

        <v-data-table
          width="200"
          v-if="selecionado"
          :headers="headers_prod"
          :items="produtos_sel"
          class="elevation-1"
        >
          <template slot="items" slot-scope="{ item }">
            <td>{{ item.id_prod.nome }}</td>
            <td v-if="item && item.valor">{{(item.valor).toFixed(2).replace('.', ',')}}</td>
            <td>{{item.id_prod.codigoDeBarras}}</td>
            <v-checkbox v-model="item.devolucao" @change="adicionarADevolucao(item)"></v-checkbox>
            <!-- <v-icon medium @click>mdi-delete</v-icon> -->
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn v-if="selecionado && (selecionado.local == 'WEB' || selecionado.local == 'APP') && selecionado.tipoPagamento == 'Cartão'" color="red" type="button" text @click="estornarCartao()">Estornar</v-btn>
          <v-btn color="red" type="button" text @click="cancelarVendas()">Cancelar Venda</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="button" text @click="gerarCodigoRecibo()">Gerar código de Recibo</v-btn>
          <v-btn color="primary" type="button" text @click="fecharDialog()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  data: () => ({
    pesquisa: "",
    dialog: false,
    dialogeditar: false,
    opcao: 0,
    codigoDeBarras: "",
    status_escolhido: "Em análise",
    devolucao_itens: [],
    editar: {
      nome: "",
      preco_atacado: 0,
      preco_venda: 0,
      tamanho: "",
      imagem: "",
      marca: "",
      quantidade: 0
    },
    produtos_sel: [],
    headers_prod: [
      {
        text: "Nome",
        align: "start",
        sortable: false,
        value: "id_prod"
      },
       { text: "Valor", value: "preco", sortable: false },
      { text: "Código", value: "codigoDeBarras", sortable: false},
      { text: "Actions", value: "actions", sortable: false }
    ],
    headers: [
      {
        sortable: false,
        text: "Código",
        value: "codigoVenda"
      },
      {
        sortable: false,
        text: `Quantidade de Produtos`,
        align: "center",
        value: "quantidade"
      },
      {
        sortable: false,
        text: "Total",
        value: "valorTotal"
      },
      {
        sortable: false,
        text: "Valor Pago",
        value: "precoPago"
      },
      {
        sortable: false,
        text: "Forma de Pagamento",
        value: "formaPagamento",
        align: "right"
      },
      {
        sortable: false,
        text: "Tipo de Pagamento",
        value: "tipoPagamento"
      },
      {
        sortable: false,
        text: "Status",
        value: "status"
      },
      {
        sortable: false,
        text: "Opções",
        value: "opcoes"
      }
    ],
    items_status: [
      "Em análise",
      "Cancelado",
      "Aguardando Pagamento",
      "Pago",
      "Aguardando Entregador",
      "Em Entrega",
      "Entregue"
    ],
    snackbar: false,
    snackbarerror: false,
    categorias: [],
    select: null,
    selecionado: null,
    items: [],
    items_busca: [],
    top: false,
    bottom: false,
    right: false,
    left: false
  }),
  beforeMount() {
    this.getVendas();
  },
  methods: {
    getVendas() {
      this.$http
        .get("/vendas")
        .then(res => {
          // console.log(res.data);
          let vendas = res.data.vendas;
          

          this.items = vendas;
        })
        .catch(err => {
          if(err.error) alert(err.error)
            
        });
    },
    getProdutoEspecifico() {
      var codigo = "codigo";
      if (this.opcao === 0) {
        codigo = "codigo";
      } else if (this.opcao === 1) {
        codigo = "marca";
      } else {
        codigo = "nome";
      }

      // console.log(codigo);

      this.$http
        .get(`produtos/${codigo}/${this.pesquisa}`)
        .then(res => {
          let produtos = res.data.produtos;
          for (let e of produtos) {
            if (e.image && e.image.key) {
              e.url = `https://api-store.emporiodasmarcas.com.br/files/product/${e.image.key}`;
            }
          }
          this.items_busca = produtos;
          // console.log(res.data);
        })
        .catch(err => {
          if(err.error) alert(err.error)
            
        });
    },
    buscarPorCodigo(){
      this.$http
        .get(`/venda/produto/${this.pesquisa}`)
        .then(res => {
          // console.log(res.data);
          this.items_busca = res.data;
        })
        .catch(err => {
          if(err.error) alert(err.error)
            
          // alert(err.error);
        });
    },
    changeToNome() {
      this.opcao = 2;
      this.getProdutoEspecifico();
    },
    changeToMarca() {
      this.opcao = 1;
      this.getProdutoEspecifico();
    },
    changeToCodigo() {
      this.opcao = 0;
      this.getProdutoEspecifico();
    },
    async estornarCartao(){
      //let pag = JSON.parse(this.selecionado.pagamento.resultado);
      // console.log(this.selecionado._id);

      this.$http.post(`/vendas/status/cancelar/card/${this.selecionado._id}`)
                .then(res => {
                  // console.log(res);
                  
                  this.snackbar = true;
                }).catch(err => {
                  // console.log(err);
                  this.snackbarerror = true;
                  
                })

              // console.log("FINALIZADO");
              
    },
    showProduto(item) {
      this.selecionado = null;
      this.produtos_sel = null;
      this.$http
        .get(`/vendas/detalhes/store/${item._id}`)
        .then(res => {
          let { venda, nome } = res.data;
          venda.comprador_nome = nome;

          let dias = new Date(venda.data);

          venda.dia =
            (dias.getDate() < 10 ? ("0" + dias.getDate()) : (dias.getDate())) +
            "/" +
            (dias.getMonth() < 10 ? ("0" + (dias.getMonth()+1)) : (dias.getMonth()+1)) +
            "/" +
            dias.getFullYear() +
            " às " +
            (dias.getHours() < 10 ? ("0"+dias.getHours()) : (dias.getHours())) +
            ":" +
            (dias.getMinutes() < 10 ? ("0" + dias.getMinutes()) : (dias.getMinutes()));

          for (let e of venda.produtos) {
            e.devolucao = false;
          }

          // console.log(venda);
          
          this.selecionado = venda;
          this.produtos_sel = venda.produtos;
          this.dialog = true;
          // console.log(venda);
          
        })
        .catch(err => {
          if(err.error) alert(err.error)
            
        });
    },
    atualizar() {
      let dados = {
        nome: this.selecionado.nome,
        tamanho: this.selecionado.tamanho,
        imagem: this.selecionado.imagem,
        preco_atacado: parseFloat(this.selecionado.valor_atacado),
        preco_venda: parseFloat(this.selecionado.valor_venda)
      };

      // console.log(this.selecionado._id);

      // console.log(dados);
      this.$http
        .put(`produto/${this.selecionado._id}`, dados)
        .then(res => {
          alert("Atualizado!");
        })
        .catch(err => {
          // console.log(err);
          if(err.error) alert(err.error)
            
          // console.log("Não foi possível fazer a atualização.");
        });
    },
    async gerarCodigoRecibo(){
      this.$http.post(`/venda/token/generate/${this.selecionado._id}`).then(
        res => {
          let codigo = res.data.code;

          window.navigator.clipboard.writeText(codigo).then(res_ => {
            alert(`${res.data.message}\nCÓDIGO COPIADO: ${res.data.code}\nCódigo válido durante 3 meses!`)
          })

        }
      ).catch(err => {
        if(err.error) alert(err.error)
      })
    },
    async atualizarStatus(status) {
      this.$http
        .put(`/vendas/status/${this.selecionado._id}`, {
          status_pedido: status
        })
        .then(res => {
          this.selecionado.status = status;
        })
        .catch(err => {
          if(err.error) alert(err.error)
            
        });
    },
    adicionarADevolucao(item) {
      // console.log("FOI");
      
      this.$http.put(`/produto/devolver/${this.selecionado._id}/${item.id_prod._id}`).then(res => {
        this.selecionado.produtos = res.data;
      }).catch(err => {
        if(err.error) alert(err.error)
            
      }
      )  ;
    },
    async realizarDevolucoes() {
      try {
      } catch (err) {
        // console.log(err);
      }
    },
    async realizarAtualizacao(...args) {
      this.top = false;
      this.bottom = false;
      this.left = false;
      this.right = false;

      for (const loc of args) {
        this[loc] = true;
      }

      if (this.codigoDeBarras == null || this.codigoDeBarras == "") {
        this.snackbarerror = true;
      } else {
        await this.$http
          .put(`/produto-ativar/${this.codigoDeBarras}`)
          .then(res => {
            this.snackbar = true;
          })
          .catch(err => {
            this.snackbarerror = true;
            // console.log(err);
          });
      }
    },
    fecharDialog() {
      this.dialog = false;
      this.selecionado = null;
    },
    seeAdress() {
      let endereco = this.selecionado;
      if (endereco && endereco.rua && endereco.numero) {
        return true;
      } else {
        return false;
      }
    },
    seeWpp() {
      if (this.selecionado && this.selecionado.wpp) {
        return true;
      } else {
        return false;
      }
    },
    cancelarVendas() {
      try {
        this.$http
          .put(`/vendas/status/cancelar/${this.selecionado._id}`)
          .then(res => (this.snackbar = true))
          .catch(err => (this.snackbarerror = true));
      } catch (err) {
        if(err.error) alert(err.error)
            
        // console.log("Error");
      }
    }
  }
};
</script>
